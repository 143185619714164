export const AccountingDocumentStatus = {
  CHARGED: 'Charged',
  CREATING: 'Creating',
  DRAFT: 'Draft',
  FAILED_PAYMENT: 'Failed Payment',
  PAID: 'Paid',
  PARTIALLY_PAID: 'Partially Paid',
  PENDING: 'Pending',
  SENT: 'Sent',
  VOID: 'Void'
};

export const AccountingDocumentType = {
  INVOICE: 'Invoice',
  BILL: 'Bill'
};
